import React, { useState, useCallback, useRef} from 'react';
import { ButtonToolbar, Button, useToaster, Notification } from 'rsuite';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import _ from 'lodash';

import { AlchemicLoader } from '../../../components/alchemic-loader';
import { ErrorBox } from '../../../components';
import { CodeViewer } from '../../../components/code-viewer';
import LetsForm, { formHelper} from '../../../components/lets-form/react-rsuite5';
import PublishFormJson from '../../../components/lets-form-forms/publish-form.json';
import { useCurrentUser } from '../../../hooks';
import { getPublishedFormUrl } from '../../../helpers/links';

import './publish-form.scss';

const GET_PUBLISHED_FORM = gql`
query($formId: String) {
  published_forms(
    filter: {
      formId: {_eq: $formId }
    }) {
    id,
    json,
    shareCode,
    date_created,
    formId,
    name,
    logo,
    border,
    description,
    framework,
    confirmationPage
  }
}`;

const DELETE_PUBLISHED_FORM = gql`
mutation($id: ID!) {
  delete_published_forms_item(id: $id) {
    id
  }
}`;

const CREATE_PUBLISHED_FORM = gql`
mutation($publishedForm: create_published_forms_input!) {
  publishedForm: create_published_forms_item(data: $publishedForm) {
    id,
    formId,
    name,
    shareCode,
    date_created,
    logo,
    border,
    description,
    framework,
    confirmationPage
  }
}
`;

const PublishFormModal = ({ value, onSubmit, onCancel }) => {
  const [publishedForm, setPublishedForm] = useState();
  const [publishedFormForm, setPublishedFormForm] = useState({
    border: true,
    logo: true,
    framework: value.framework,
    confirmationPage: false
  });
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const toaster = useToaster();
  const refPublishForm = useRef();
  const { isPaying, user } = useCurrentUser();
  const hasConnectors = !_.isEmpty(value.form.connectors);

  const {loading: loadingPublishedForm } = useQuery(GET_PUBLISHED_FORM, {
    variables: {
      formId: value.formId
    },
    skip: _.isEmpty(value.formId),
    fetchPolicy: 'network-only',
    onCompleted: result => {
      if (!_.isEmpty(result.published_forms)) {
        setPublishedForm(result.published_forms[0])
      }
    }
  });

  let shareLink, iframeLink;
  if (publishedForm) {
    shareLink = getPublishedFormUrl(publishedForm);
    iframeLink = `<iframe\n  src="${shareLink}"\n  title="${publishedForm.name}"\n  style="border:none;"\n  width="100%"\n></iframe>`;
  }

  const handleDeleteLink = useCallback(
    async () => {
      try {
        setLoading(true);
        await client.mutate({
          mutation: DELETE_PUBLISHED_FORM,
          variables: {
            id: publishedForm.id
          }
        });
        setLoading(false);
        toaster.push(
          <Notification type="info" header="Unpublished!" closable>
            <div>
              The form was unpublished successfully!
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        onSubmit({});
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error" closable>
            <div>
              <b>Something went wrong</b> unpublishing the form, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, onSubmit, publishedForm, toaster]
  );

  const handleCreatePublishedFormLink = useCallback(
    async () => {
      // skip if not valid
      if (!await refPublishForm.current.validate()) {
        return;
      }

      try {
        setLoading(true);
        const response = await client.mutate({
          mutation: CREATE_PUBLISHED_FORM,
          variables: {
            publishedForm: {
              json: JSON.stringify({
                ...value.form,
                framework: value.framework,
                size: value.size
              }, null, 2),
              name: value.form.name,
              formId: value.formId,
              organization: user.organization.id,
              projectId: value.projectId,
              shareCode: Math.random().toString(36).slice(2),
              ..._.pick(publishedFormForm, ['border', 'logo', 'description', 'framework', 'confirmationPage'])
            }
          }
        });
        setLoading(false);
        toaster.push(
          <Notification type="info" header="Created!" closable>
            <div>
              The form was published succesfully
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setPublishedForm(response.data.publishedForm);
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error!" closable>
            <div>
              <b>Something went wrong</b> publishing the form, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, publishedFormForm, toaster, user.organization.id, value.form, value.formId, value.framework, value.projectId, value.size]
  );

  if (loadingPublishedForm) {
    return (
      <div className="lf-publish-form">
        <AlchemicLoader margin={50} />
      </div>
    );
  }

  return (
    <div className="lf-publish-form">
      {shareLink && (
        <>
          <div className="description">
            Use the HTML fragment below to add the form to any web page. Any further changes to the form will not
            impact the published form, unpublish the form and publish it again to apply changes.
          </div>

          <CodeViewer
            style={{ marginTop: '20px', marginBottom: '20px' }}
            files={[
              {
                title: 'Iframe',
                description: 'Iframe HTML fragment for published form',
                content: iframeLink,
                language: 'html',
                lineNumbers: false
              },
              {
                title: 'Link',
                description: 'Link for published form',
                content: shareLink,
                lineNumbers: false
              }
            ]}
          />



          <LetsForm
            form={PublishFormJson}
            ref={refPublishForm}
            locale="en-US" // or will show "SI"
            defaultValues={publishedForm}
            plaintext={true}
          />

          <ButtonToolbar>
            <Button
              disabled={loading}
              appearance="ghost"
              onClick={handleDeleteLink}
            >Unpublish form</Button>
            <Button
              appearance="primary"
              disabled={loading}
              onClick={() => onSubmit(publishedForm)}
            >Close</Button>
          </ButtonToolbar>
        </>
      )}
      {!shareLink && (
        <>
          {!hasConnectors && (
            <ErrorBox error={`Form doesn't have any connectors, add one in the "Connectors" tab`} />
          )}
          <div className="warning">
            Click on the button below to publish the form and create an embeddable IFRAME fragment for it.<br/>
            <b>The link for the published form is public, anyone can see it, pay attention on not including sensible information.</b><br/>
          </div>
          <LetsForm
            form={formHelper(PublishFormJson)
              .skip(isPaying)
              .setField('logo', 'disabled', true)
              .setField('logo', 'hint', 'Logo is mandatory for free plans')
              .setField('logo', 'tooltip', true)
              .form()
            }
            defaultValues={publishedFormForm}
            hideSubmit={true}
            hideCancel={true}
            ref={refPublishForm}
            onChange={setPublishedFormForm}
          />
          <ButtonToolbar>
            <Button
              disabled={loading || !hasConnectors}
              appearance="primary"
              onClick={handleCreatePublishedFormLink}
            >Publish form</Button>
            <Button
              appearance="default"
              disabled={loading}
              onClick={onCancel}
            >Close</Button>
          </ButtonToolbar>
        </>
      )}
    </div>
  );
};

export { PublishFormModal };
