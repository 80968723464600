import { createContext, useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';

import { lfLogPlugin } from '../components/lets-form/helpers/lf-log';

const CodePlugPlugins = {};

const CodePlugContext = createContext();

const generateKey = (view, options) => {
  // generate a postfix from id or key in case the view has a displayName
  let id = '';
  if (options?.id != null) {
    id = `-${options.id}`;
  } else if (options?.key) {
    id = `-${options.key}`;
  }
  //const id = options != null && options.id != null ? `-${options.id}` : '';
  if (view != null && view.displayName != null) {
    return `${view.displayName}${id}`;
  } else if (
    view != null &&
    view.prototype != null &&
    view.prototype.constructor != null &&
    view.prototype.constructor.displayName != null
  ) {
    return `${view.prototype.constructor.displayName}${id}`;
  } else if (view != null && view.prototype != null && view.prototype.namespace != null) {
    return `${view.prototype.namespace}${id}`;
  } else if (view != null && view.name != null) {
    return `${view.name}${id}`;
  } else if (!_.isEmpty(options?.key)) {
    return options.key;
  } else if (!_.isEmpty(options?.id)) {
    return options.id;
  } else {
    console.log(
      `Both the "namespace" and "displayName" properties were missing from a registered view,
      it's needed to generate the correct key reference for child components in React`
    );
  }
};

const definePlugin = (name, f) => {
  CodePlugPlugins[name] = f;
};

const useRegion = (name, mainFilter = () => true) => {

  const { regions } = useContext(CodePlugContext);

  return (props, filter = () => true ) => (
    (regions && regions[name] ? regions[name] : [])
      .filter(mainFilter)
      .filter(filter)
      .map(({ View, params }) => (
        <View
          {..._.omit(params, 'key')}
          {...props}
          key={generateKey(View, params)}
        />
      ))
    );
};

const useRawRegion = (name, mainFilter = () => true) => {
  const { regions } = useContext(CodePlugContext);
  return (regions && regions[name] ? regions[name] : [])
    .filter(mainFilter)
};

const useMapRegion = (name, mainFilter = () => true) => {
  const { regions } = useContext(CodePlugContext);

  return (predicate, filter = () => true ) => (
    (regions && regions[name] ? regions[name] : [])
      .filter(mainFilter)
      .filter(filter)
      .map(obj => predicate(obj))
      .filter(Boolean)
    );
};

const makePluginContext = (currentContent = {}, plugin) => {
  const pluginName = _.isString(plugin) ? plugin : plugin.plugin;

  if (_.isEmpty(pluginName)) {
    throw new Error('Plugin Name is missing');
  }
  if (!_.isFunction(CodePlugPlugins[pluginName])) {
    throw new Error(`Plugin "${pluginName}" is missing or wrongly initialized`);
  }

  const tempContext = {
    regions: {
      ...(currentContent.regions ?? {})
    },
    events: {
      ...(currentContent.events ?? {})
    }
  };

  CodePlugPlugins[pluginName](
    // pass factory methods
    {
      registerView: (name, View, params) => {
        if (!tempContext.regions[name]) {
          tempContext.regions[name] = [];
        }
        tempContext.regions[name] = [
          ...tempContext.regions[name],
          {
            View, params
          }
        ];
      },
      listenTo: (name, callback) => {
        if (!tempContext.events[name]) {
          tempContext.events[name] = [];
        }
        tempContext.events[name] = [
          ...tempContext.events[name],
          callback
        ];
      }
    },
    // pass plugin static params
    _.isObject(plugin) ? _.omit(plugin, 'plugin') : null
  );

  return tempContext;
};

const CodePlugProvider = forwardRef(
  ({
    name,
    children,
    plugins = [],
    debug = true
  },
  ref
) => {
  useImperativeHandle(ref, () => ({
    updatePlugins: (newPlugins) => {
      // rebuild completely the CodePlug context
      const newInitialPluginContext = plugins
        .filter(Boolean)
        .reduce(
          makePluginContext,
          parentCodePlugContext ? { ...parentCodePlugContext } : {}
        );
      const newPluginsList = [
        ...(parentCodePlugContext?.plugins ?? []),
        ...newPlugins.map(plugin => _.isObject(plugin) ? plugin.plugin : plugin).filter(plugin => !_.isEmpty(plugin))
      ];
      // finally set it
      setCodePlugState({
        ...newInitialPluginContext,
        plugins: newPluginsList
      });
    }
  }));

  // get the parent context and merge the plugin list from parent
  const parentCodePlugContext = useContext(CodePlugContext);

  const initialCodePlugContext = plugins
    .filter(Boolean)
    .reduce(
      makePluginContext,
      parentCodePlugContext ? { ...parentCodePlugContext } : {}
    );

  const pluginList = [
    ...(parentCodePlugContext?.plugins ?? []),
    ...plugins
      .filter(Boolean)
      .map(plugin => _.isObject(plugin) ? plugin.plugin : plugin).filter(plugin => !_.isEmpty(plugin))
  ];
  // show debug if needed
  if (debug) {
    lfLogPlugin(
      'Plugin context' +
      (name ? ` "${name}"` : '') +
      '\n' +
      (initialCodePlugContext.regions ? '\nregions: ' + Object.keys(initialCodePlugContext.regions).join(', ') : '') +
      (initialCodePlugContext.events ? '\nevents: ' + Object.keys(initialCodePlugContext.events).join(', ') : '') +
      (pluginList.length !== 0 ? '\nplugins: ' + pluginList.join(', ') : '') +
      '\n '
    );
  }
  const [codePlugState, setCodePlugState] = useState({
    ...initialCodePlugContext,
    plugins: pluginList
  });

  return (
    <CodePlugContext.Provider value={codePlugState}>
      {children}
    </CodePlugContext.Provider>
  );
});



/**
 * useListenTo
 * Use this to publish some events in a registered view, it's the last resort before adding regions here and there
 * to call shared part of codes, it creates impertative methods
 *
 *   const attrs = useListenTo({
 *     myMethod: params => {
 *       // do something
 *     }
 *   });
 *
 *   return (
 *     <div {...attrs}>
 *       // my custom view
 *     </div>
 *   );
 *
 * In another part of the code, in order to call the "myMethod" event
 *
 *   const callMyMethod = useRegionCallback('myMethod');
 *   // ...
 *   const handleClick = () => {
 *     callMyMethod(42);
 *   }
 *
 * @param {*} events
 * @returns
 */
const useListenTo = (events) => {
  const ref = useRef();
  useEffect(
    () => {
      const current = ref.current;
      const handleEvent = (e) => {
        if (e.detail) {
          const { method, params } = e.detail;
          if (_.isFunction(events[method])) {
            events[method].apply(this, params);
          }
        }
      };

      current.addEventListener('codePlugProxy', handleEvent);
      return () => current.removeEventListener('codePlugProxy', handleEvent);
    },
    [events, ref] // TODO improve this
  );

  return {
    ref,
    'data-code-plug-callback': Object.keys(events).toString()
  };
};

const useEvent = (name) => {
  const { events } = useContext(CodePlugContext);

  const handle = useCallback(
    function () {

      // call static events
      if (!_.isEmpty(events[name])) {
        const params = Array.from(arguments);
        events[name].forEach(f => f.apply(this, params));
      }
      // call events attached to views
      const params = Array.from(arguments);
      const views = document.querySelector(`[data-code-plug-callback*="${name}"]`);

      if (views) {
        views.dispatchEvent(new CustomEvent(
          'codePlugProxy',
          {
            detail: {
              method: name,
              params
            }
          }
        ));
      }
    },
    [name, events]
  );

  return handle;
};

export {
  definePlugin,
  CodePlugProvider,
  useRegion,
  useMapRegion,
  useRawRegion,
  useEvent,
  useListenTo
};
