import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Tabs } from 'rsuite';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { CopyAndPasteButton } from '../copy-and-paste';

import './code-viewer.scss';


const RawCodeViewer = ({
  copyToClipboard,
  activeFile,
}) => (
  <>
    <div className="buttons">
      <div className="left">
        {activeFile.description ?? activeFile.title ?? activeFile.fileName ?? ''}
      </div>
      <div className="right">
        <CopyAndPasteButton
          label="Copy to clipboard"
          size="xs"
          float="none"
          notification={activeFile.fileName ?
            `${activeFile.fileName} succesfully copied to clipboard` :
            'Code succesfully copied to clipboard'
          }
          text={activeFile.content}
        />
      </div>

    </div>
    <div>
      <SyntaxHighlighter
        className="syntax"
        showLineNumbers={activeFile.lineNumbers ?? true}
        language={activeFile.language ?? 'html'}
        style={nightOwl}
        customStyle={{
          marginTop: '0px',
          marginBottom: '0px'
        }}
      >{activeFile.content}</SyntaxHighlighter>
    </div>
  </>
);


const CodeViewer = ({
  copyToClipboard = true,
  files = [],
  style
}) => {

  const [activeFile/*, setActiveFile*/] = useState(Array.isArray(files) && files.length !== 0 ? files[0] : null);

  if (!activeFile) {
    return (
      <div className="lf-code-viewer">
        No files to show
      </div>
    );
  }

  if (files.length > 1) {
    return (
      <div className="lf-code-viewer" style={style}>
        <Tabs defaultActiveKey="0" appearance="pills">
          {files.map((activeFile, idx) => (
            <Tabs.Tab eventKey={String(idx)} title={activeFile.title}>
              <RawCodeViewer
                activeFile={activeFile}
                copyToClipboard={copyToClipboard}
              />
            </Tabs.Tab>
          ))}
        </Tabs>
      </div>
    );
  }

  return (
    <div className="lf-code-viewer" style={style}>
      <RawCodeViewer
        activeFile={activeFile}
        copyToClipboard={copyToClipboard}
      />
    </div>
  );
};

export { CodeViewer };
