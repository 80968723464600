import React, { useCallback, useState } from 'react';
import { Dropdown } from 'rsuite';
import _ from 'lodash';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { useModal } from '../../../components/modal';
import { useTracking } from '../../../hooks';

import { PublishFormModal } from './publish-form';

const PublishFormMenuItem = () => {
  const [publishedForm, setPublishedForm] = useState();
  const tracking = useTracking();
  const formId = useFormBuilderStore(state => state.formId);
  const projectId = useFormBuilderStore(state => state.projectId);
  const size = useFormBuilderStore(state => state.size);
  const framework = useFormBuilderStore(state => state.framework);
  const form = useFormBuilderStore(state => state.form);

  const { open: openPublishForm, close: closePublishForm } = useModal({
    view: PublishFormModal,
    size: 'sm',
    name: 'publish_form',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Publish Form'
  });

  const handleClick = useCallback(
    async () => {
      tracking.sendEvent('form.publish');
      const newPublishedForm = await openPublishForm({
        publishedForm,
        form,
        formId,
        projectId,
        framework,
        size
      });
      closePublishForm();

      if (newPublishedForm && Object.keys(newPublishedForm).length !== 0) {
        setPublishedForm(newPublishedForm)
      } else if (_.isObject(newPublishedForm) && Object.keys(newPublishedForm).length === 0) {
        setPublishedForm(null);
      }
    },
    [closePublishForm, form, formId, framework, openPublishForm, projectId, publishedForm, size, tracking]
  );

  return (
    <Dropdown.Item onSelect={handleClick}>Publish form</Dropdown.Item>
  );
};

export { PublishFormMenuItem };
