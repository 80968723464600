import React, { useCallback, useState } from 'react';
import { useQuery, gql, useApolloClient } from '@apollo/client';

import { useCurrentUser, useNotification } from '../../../hooks';
import { DashboardSidebarList } from '../../../components/dashboard-sidebar-list';
import { getProjectFormUrl, getPublishedFormUrl } from '../../../helpers/links';

const GET_PUBLISHED_FORMS = gql`
query($organizationId: GraphQLStringOrFloat) {
  published_forms (
    filter: {
      organization: {
        id: {
          _eq: $organizationId
        }
      }
    }
  ) {
    id,
    name,
    shareCode,
    formId,
    projectId,
    date_created,
    user_created {
      id,
      organization {
        id
      }
    }
  }
}`;

const DELETE_PUBLISHED_FORM = gql`
mutation($id: ID!) {
  delete_published_forms_item(id: $id) {
    id
  }
}`;





const PublishedFormsSidebar = () => {
  const { user } = useCurrentUser({ redirectToLogin: false });
  const notify = useNotification();
  const client = useApolloClient();
  const [disabled, setDisabled] = useState(false);
  const { data, loading, refetch } = useQuery(GET_PUBLISHED_FORMS, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      organizationId: user?.organization.id
    }
  });

  const handleDeleteLink = useCallback(
    async (publishedForm) => {
      if (!window.confirm(`Delete published form "${publishedForm.name}"`)) {
        return;
      }
      try {
        setDisabled(true);
        await client.mutate({
          mutation: DELETE_PUBLISHED_FORM,
          variables: {
            id: publishedForm.id
          }
        });
        setDisabled(false);
        notify.success('Published form', 'The published form was deleted successfully!');
        refetch();
      } catch(e) {
        notify.error('Published form', e);
        setDisabled(false);
      }
    },
    [client, notify, refetch]
  );

  const handleButton = useCallback(
    (button, publishedForm) => {
      if (button === 'copy') {
        navigator.clipboard.writeText(getPublishedFormUrl(publishedForm));
        notify.success(
          'Link copied!',
          <div>Published form link was succesfully copied on the clipboard</div>
        );
      } else if (button === 'delete') {
        handleDeleteLink(publishedForm);
      }
    },
    [handleDeleteLink, notify]
  );

  return (
    <DashboardSidebarList
      title="Published forms"
      description="..."
      data={data && data.published_forms ? data.published_forms : []}
      buttons={[
        { button: 'delete', label: 'Delete' },
        { button: 'copy', label: 'Copy link' }
      ]}
      onButton={handleButton}
      onClickRow={data => {
        window.location = getProjectFormUrl(data)
      }}
      loading={loading}
      disabled={disabled}
      ComponentRow={({ data }) => <span>{data.name}</span>}
      emptyPlaceholder={
        <>
          There are no published forms, to create one: open a saved form then
          {' '}
          <em>Form</em> &rarr; <em>Publish form</em>
        </>
      }
    />
  );
};

export { PublishedFormsSidebar };
