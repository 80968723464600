

export const getDesignerUrl = () => process.env.NODE_ENV === 'development' ?
  'http://localhost:3000' : 'https://designer.letsform.dev';

export const getProjectFormUrl = ({ formId, projectId }) => `${getDesignerUrl()}/projects/${projectId ?? '0'}/forms/${formId}`;

export const getPublishedFormUrl = ({
  shareCode,
  framework = 'react-rsuite5'
}) => `${getDesignerUrl()}/w/${shareCode}/${framework}`;